<template>
  <base-layout>
    <add-edit-billing-plan :vehicleTypes="vehicleTypes" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus title="Billing Plan Management" @plus="add" />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-y-4 md:space-y-0 md:items-center md:flex-row"
        >
          <card-tax-stat
            title="Earning Without Tax"
            :value="`${symbol} ${indexMetaData.summary.earning_without_tax}`"
            variant="yellow"
            class="mr-3"
          />
          <card-tax-stat
            title="Earning With Tax"
            :value="`${symbol} ${indexMetaData.summary.earning_with_tax}`"
            variant="green"
            class=""
          />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>#{{ item.id | onlyLastFive }}</fsTableRowItem>

            <fsTableRowItem>
              <div
                @click="edit(item)"
                class="font-semibold text-blue-700 cursor-pointer"
              >
                {{ item.name }}
              </div>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.tax_plan ? item.tax_plan.name : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.fleet ? item.fleet.name : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ getCurrencySymbol(item) }} {{ item.max_billing_per_ride }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ getCurrencySymbol(item) }} {{ item.vehicle_reservation_fees }}
            </fsTableRowItem>

            <fsTableRowItem
              >{{ getCurrencySymbol(item) }}
              {{ item.default_unit_fees }}</fsTableRowItem
            >

            <fsTableRowItem>
              <x-status
                :variant="item.status_active ? 'green' : 'orange'"
                :text="item.status_active ? 'Active' : 'Inactive'"
                size="small"
              />
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :currentVal="item.status_active"
                  :onOffEndpoint="getActionsEndpoint(item.id)"
                  actionOnKey="status_active"
                  actionOffKey="status_active"
                  :actionOnVal="true"
                  :actionOffVal="false"
                  @actionOn="item.status_active = true"
                  @actionOff="item.status_active = false"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <!-- fs-table-footer -->
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import OtoSearch from '@/components/ui/OtoSearch'
import XStatus from '@/components/badge/XStatus'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
import { EventBus } from '@/utils/EventBus'
import { mapGetters } from 'vuex'
import CardTaxStat from '@/components/cards/TaxStat'
import AddEditBillingPlan from './BillingPlanAddEdit'

export default {
  name: 'BillingPlans',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    OtoSearch,
    DateRangePicker,
    CardTaxStat,
    XStatus,
    ExportAsDropdown,
    MoreActionsDropdown,
    AddEditBillingPlan,
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          earning_without_tax: 0,
          earning_with_tax: 0,
        },
        count: {
          total: 0,
        },
      },
      symbol: '$',
      indexDataEndpoint: BillingPlanConfig.api.index,
      vehicleTypes: [],
      tableHeaders: [
        {
          name: 'Billing ID',
          width: '10%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Billing Name',
          width: '15%',
          field: 'name',
          sort: true,
          order: '',
        },
        {
          name: 'Tax Name',
          width: '15%',
          field: 'tax_plan',
          sort: true,
          order: '',
        },
        {
          name: 'Assigned Fleet',
          width: '15%',
          field: 'fleet',
          sort: true,
          order: '',
        },
        {
          name: 'Max Billing Per Ride',
          width: '15%',
          field: 'max_billing_per_ride',
          sort: true,
          order: '',
        },
        {
          name: 'Vehicle Reservation Charge',
          width: '15%',
          field: 'vehicle_reservation_fees',
          sort: true,
          order: '',
        },
        {
          name: 'Default Unit Fees',
          width: '20%',
          field: 'default_unit_fees',
          sort: true,
          order: '',
        },
        {
          name: 'Status',
          width: '10%',
          field: 'status_active',
          sort: null,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  created() {
    const vehicleTypeMap = {
      'SCOOTER': {
        text: 'Scooter',
        value: 'P',
      },
      'SCOOTER PRO': {
        text: 'Scooter Pro',
        value: 'PP',
      },
      'EBIKE': {
        text: 'E-Bike',
        value: 'E',
      },
      'BIKE': {
        text: 'Bike',
        value: 'S',
      },
      'COCO': {
        text: 'SuperCoco',
        value: 'C',
      },
      'KENOTA': {
        text: 'Kenota',
        value: 'K',
      },
    }
    const orgVehicleTypes = this?.$org?.vehicle_type || []
    orgVehicleTypes.forEach((t) => this.vehicleTypes.push(vehicleTypeMap[t]))
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    window.addEventListener(BillingPlanConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    add: function() {
      EventBus.$emit(BillingPlanConfig.events.editingData, {
        // enable status-switch by default
        status_active: true,
      })
      dispatchEvent(new Event(BillingPlanConfig.events.sorToggle))
      console.log('asdfdsaf')
    },
    edit: async function(item) {
      console.log(item)

      var billingDetails = await this.$http
        .get(BillingPlanConfig.api.details(item.id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      billingDetails.tax_plan = billingDetails.tax_plan
        ? billingDetails.tax_plan.id
        : null
      billingDetails.existing_fleet = billingDetails.fleet
      billingDetails.fleet = billingDetails.fleet
        ? billingDetails.fleet.id
        : null
      console.log('Morphed...', billingDetails)

      EventBus.$emit(BillingPlanConfig.events.editingData, billingDetails)
      dispatchEvent(new Event(BillingPlanConfig.events.sorToggle))
    },
    view: function(item) {
      console.log(item)
      EventBus.$emit(BillingPlanConfig.events.viewingData, item)
      this.$modal.show(BillingPlanConfig.events.viewingData)
    },
    getActionsEndpoint(id) {
      return BillingPlanConfig.api.update(id)
    },
    getCurrencySymbol(item) {
      // console.log(item.fleet.country.currency_symbol)
      return item?.fleet?.country?.currency_symbol || this.symbol || '$'
    },
  },
}
</script>

<style></style>
